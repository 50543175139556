<template>
  <b-modal
    ref="modal-import-excel"
    :title="'Import excel'"
    @hidden="$emit('hidden')"
    ok-title="Import"
    @ok.prevent="saveExcel"
    scrollable
  >
    <b-row>
      <b-col cols="12">
        <b-form-group label="File">
          <b-form-file
            v-model="file"
            placeholder="Choose an excel..."
            drop-placeholder="Choose an excel..."
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal";
import schedulesService from "../services/schedules.service";
export default {
  mixins: [modalMixin],
  data() {
    return {
      file: null,
    };
  },
  mounted() {
    this.toggleModal("modal-import-excel");
  },
  methods: {
    async saveExcel() {
      try {
        this.addPreloader();
        const formData = new FormData();
        formData.append("file", this.file);
        await schedulesService.importSchedules(formData);
        this.showSuccessSwal();
        this.$emit("reload-schedules");
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col cols="12" md="6">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Modules">
              <b-form-select
                v-model="module_id"
                :options="modulesReal"
                placeholder="Select Module"
                text-field="name"
                value-field="id"
                v-if="currentUser.modul_id == 17 || currentUser.role_id == 1"
              ></b-form-select>
              <b-form-select
                v-else
                v-model="module_id"
                :options="{ id: null, name: 'YOUR MODULE' }"
                disabled
                text-field="name"
                value-field="id"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="From">
              <kendo-datepicker
                :min="minDate"
                :max="maxDate"
                v-model="initDateSelect"
                :format="'MM/dd/yyyy'"
                name="created_date"
                id="created_date"
              ></kendo-datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="To">
              <kendo-datepicker
                :min="minDate"
                :max="maxDate"
                v-model="hearChange"
                :format="'MM/dd/yyyy'"
                name="created_date"
                id="created_date"
                :disabled="true"
              ></kendo-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="6">
        <b-row>
          <b-col md="4" class="text-left">
            <b-button
              variant="gradient-danger"
              class="btn-icon rounded-circle mr-1"
              @click="changeWeek(-1)"
            >
              <feather-icon icon="ChevronLeftIcon" />
            </b-button>
            <b-button
              variant="gradient-danger"
              class="btn-icon rounded-circle"
              @click="changeWeek(1)"
            >
              <feather-icon icon="ChevronRightIcon" />
            </b-button>
          </b-col>
          <b-col md="8" class="text-right d-flex justify-content-between align-items-center">
            <b-button variant="info" @click="modalImportExcel = true">
              Import Excel
            </b-button>
            <b-button variant="success" @click="getLastWeek">
              FILL FROM PREVIOUS WEEK
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="row">
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th scope="col">
              <b>User</b>
            </b-th>
            <b-th scope="col">
              <b>Mon {{ days.monday }}</b>
            </b-th>
            <b-th scope="col">
              <b>Tue {{ days.tuesday }}</b>
            </b-th>
            <b-th scope="col">
              <b>Wed {{ days.wednesday }}</b>
            </b-th>
            <b-th scope="col">
              <b>Thu {{ days.thursday }}</b>
            </b-th>
            <b-th scope="col">
              <b>Fri {{ days.friday }}</b>
            </b-th>
            <b-th scope="col">
              <b>Sat {{ days.saturday }}</b>
            </b-th>
            <b-th scope="col">
              <b>Sun {{ days.sunday }}</b>
            </b-th>
            <b-th scope="col">
              <b-form-checkbox
                id="check-header"
                v-model="checkAll"
                name="check-header"
                @change="pusherUsers"
              />
            </b-th>
          </b-tr>
        </b-thead>
        <tbody>
          <b-tr v-for="item in show" :key="item.id">
            <b-th scope="row" style="cursor: pointer">
              {{ item.first_name }} {{ item.last_name }}
            </b-th>
            <b-td v-for="schedule in item.json_data" :key="schedule.id">
              <small id="emailHelp" class="form-text text-muted"
                >CLOCK IN</small
              >
              <b-form-select
                v-model="schedule.checking"
                @change="insertSchedules(item.id, schedule)"
              >
                <b-form-select-option hidden :value="null"
                  >Hour</b-form-select-option
                >
                <b-form-select-option
                  v-for="(hours, ind) in hours"
                  :key="ind"
                  :value="hours.value"
                  :hidden="hours.hour == '24:00'"
                >
                  {{ hours.hour }}
                </b-form-select-option>
              </b-form-select>
              <small id="emailHelp" class="form-text text-muted"
                >START BREAK</small
              >
              <b-form-select
                class="custom-select hours"
                v-model="schedule.begining_break"
                @change="insertSchedules(item.id, schedule)"
              >
                <b-form-select-option hidden :value="null"
                  >Hour</b-form-select-option
                >
                <b-form-select-option
                  v-for="(hours, ind) in hours"
                  :key="ind"
                  :value="hours.value"
                  :hidden="hours.hour == '24:00'"
                >
                  {{ hours.hour }}
                </b-form-select-option>
              </b-form-select>
              <small id="emailHelp" class="form-text text-muted"
                >END BREAK</small
              >
              <b-form-select
                class="custom-select hours"
                v-model="schedule.finish_break"
                @change="insertSchedules(item.id, schedule)"
              >
                <b-form-select-option hidden :value="null"
                  >Hour</b-form-select-option
                >
                <b-form-select-option
                  v-for="(hours, ind) in hours"
                  :key="ind"
                  :value="hours.value"
                  :hidden="hours.hour == '24:00'"
                >
                  {{ hours.hour }}
                </b-form-select-option>
              </b-form-select>
              <small id="emailHelp" class="form-text text-muted"
                >CLOCK OUT</small
              >
              <b-form-select
                class="custom-select hours"
                v-model="schedule.checkout"
                @change="insertSchedules(item.id, schedule)"
              >
                <b-form-select-option hidden :value="null"
                  >Hour</b-form-select-option
                >
                <b-form-select-option
                  v-for="(hours, ind) in hours"
                  :key="ind"
                  :value="hours.value"
                >
                  {{ hours.hour }}
                </b-form-select-option>
              </b-form-select>
              <small id="emailHelp" class="form-text text-muted"
                >COUNT THE BREAK</small
              >
              <b-form-select
                class="custom-select hours"
                v-model="schedule.count_break"
                :style="schedule.count_break == 0 ? 'color:red' : 'color:green'"
                @change="insertSchedules(item.id, schedule)"
              >
                <b-form-select-option :value="1" style="color: green !important"
                  >True</b-form-select-option
                >
                <b-form-select-option value="0" style="color: red !important"
                  >False</b-form-select-option
                >
              </b-form-select>
              <div class="my-2">
                <b-button
                  variant="gradient-danger"
                  class="w-100"
                  @click="openModalCopySchedules(item, schedule)"
                  ><feather-icon
                    icon="ClipboardIcon"
                    class="mr-1"
                    v-if="currentBreakPoint == 'xxl'"
                  />
                  COPY</b-button
                >
              </div>
            </b-td>
            <b-td>
              <div class="container">
                <div class="text-center" style="margin-top: 108px">
                  <b-form-checkbox
                    :id="`check-header-${item.id}`"
                    v-model="users"
                    :value="item.id"
                    :name="`check-header-${item.id}`"
                    aria-label="Checkbox for following text input"
                  />
                </div>
              </div>
            </b-td>
          </b-tr>
        </tbody>
      </b-table-simple>
    </div>
    <modal-copy-schedules
      v-if="modalCopy"
      :date-copied="dateCopied"
      :dates-add="datesAdd"
      @reset-module-users="getModuleUsers"
      @set-dates-add-empty="datesAdd = []"
      @set-users-empty="users = []"
      @push-dates="pushDates"
      @change-date="changeDate"
      @close-modal-copy="closeModalCopy"
      @hidden="modalCopy = false"
    ></modal-copy-schedules>
    <modal-import-from-excel
      v-if="modalImportExcel"
      @hidden="modalImportExcel = false"
      @reload-schedules="reloadSchedules"
    ></modal-import-from-excel>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import schedulesService from "./services/schedules.service";
import ModalCopySchedules from "./modals/ModalCopySchedules.vue";
import ModalImportFromExcel from './modals/ModalImportFromExcel.vue'
export default {
  props: ["global"],
  components: {
    ModalCopySchedules,
    ModalImportFromExcel
  },
  data() {
    return {
      show: [],
      min: new Date(1950, 0, 1, 0, 0, 0),
      max: new Date(3049, 11, 31, 24, 0, 0),
      week: 0,
      initDate: moment().startOf("week").add(1, "days").format("YYYY-MM-DD"),
      endDate: moment().endOf("week").add(1, "days").format("YYYY-MM-DD"),
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(2050, 9, 1),
      initDateSelect: "",
      hearChange: "",
      days: {
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
        sunday: "",
      },
      schedules_users: [],
      duplicate_hours: [],
      ruta_dinamic: "",
      modules: [],
      module_id: "",
      module_send: "",
      modalCopy: false,
      modalImportExcel: false,
      hours_assig: [],
      dateCopied: "",
      datesAdd: [],
      hours: [],
      users: [],
      checkAll: false,
      hoursCopy: {
        user: "",
        checking: "",
        beginin: "",
        finish: "",
        checkout: "",
      },
    };
  },
  created() {
    this.addHours();
    if ([1, 2].includes(this.currentUser.role_id)) {
      this.module_id = this.currentUser.modul_id;
    }
    // this.getModuleUsers();
    this.initDateSelect = this.initDate;
    this.loadModules();
    // this.count();
    for (let i = 0; i < this.currentUser.arrRoles.length; i++) {
      if (this.currentUser.modul_id == this.currentUser.arrRoles[i].module_id) {
        this.ruta_dinamic = this.currentUser.arrRoles[i].module_route;
      }
    }
  },
  methods: {
    // count() {
    //   this.$children[0].axiosaccountant();
    // },
    closeModalCopy() {
      this.modalCopy = false;
      this.getModuleUsers();
    },
    changeDate(date) {
      this.dateCopied = date;
    },
    async getModuleUsers() {
      this.addPreloader();
      try {
        this.datesAdd = [];
        this.initDateSelect = this.initDate;
        this.hearChange = this.endDate;
        const params = {
          id_module: this.module_id,
          start_date: this.initDate,
          final_date: this.endDate,
        };
        const { data } = await schedulesService.getUsersModule(params);
        this.show = data;
        let temporary_json_data = [];
        for (let i = 0; i < this.show.length; i++) {
          let day = [];
          for (let index = 1; index <= 7; index++) {
            day.push({
              date: moment()
                .startOf("week")
                .add(this.week, "week")
                .add(index, "days")
                .format("YYYY-MM-DD"),
              checking: null,
              begining_break: null,
              finish_break: null,
              checkout: null,
              count_break: 1,
              id: new Date().getUTCMilliseconds() + Math.random() * 10000000000,
            });
          }
          if (this.show[i].json_data === null) {
            this.show[i].json_data = day;
          } else {
            temporary_json_data = this.show[i].json_data;
            this.show[i].json_data = day;
            for (let k = 0; k < this.show[i].json_data.length; k++) {
              for (let j = 0; j < temporary_json_data.length; j++) {
                if (
                  this.show[i].json_data[k].date == temporary_json_data[j].date
                ) {
                  this.show[i].json_data[k].checking =
                    temporary_json_data[j].checking;
                  // ***********
                  this.show[i].json_data[k].begining_break =
                    temporary_json_data[j].begining_break;
                  // **********
                  this.show[i].json_data[k].finish_break =
                    temporary_json_data[j].finish_break;
                  // **********
                  if (temporary_json_data[j].checkout == "00:00:00.000000") {
                    this.show[i].json_data[k].checkout = "24:00:00.000000";
                  } else {
                    this.show[i].json_data[k].checkout =
                      temporary_json_data[j].checkout;
                  }
                  this.show[i].json_data[k].count_break =
                    temporary_json_data[j].count_break;
                }
              }
            }
          }
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
    reloadSchedules(){
      this.modalImportExcel = false
      this.getModuleUsers();
    },
    changeWeek(week) {
      this.addPreloader();
      this.week = this.week + week;
      this.initDate = moment()
        .startOf("week")
        .add(this.week, "week")
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.endDate = moment()
        .endOf("week")
        .add(this.week, "week")
        .add(1, "days")
        .format("YYYY-MM-DD");
      // this.getModuleUsers();
      this.initDateSelect = this.initDate;
    },
    async insertSchedules(usr, sch) {
      if (this.currentUser.modul_id == 17 || this.currentUser.role_id == 1) {
        try {
          const params = {
            sch,
            user: usr,
            id_super: this.currentUser.user_id,
            id_module: this.module_id,
          };
          const res = await schedulesService.insertSchedules(params);
          if (res.data.text == 3) {
            this.showToast(
              "error",
              "top-right",
              "Oops",
              "XIcon",
              "Attendance has already been marked!"
            );
          } else if (res.data.text == "0") {
            this.showToast(
              "error",
              "top-right",
              "Oops",
              "XIcon",
              "This schedule is already covered!"
            );
          } else if (res.data.text == 1) {
            this.showToast(
              "success",
              "top-right",
              "Congratulations",
              "CheckIcon",
              "You've successfully done it!"
            );
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      } else {
        this.showErrorSwal("You are not a supervisor of this module!");
      }
    },
    async getLastWeek() {
      if (this.currentUser.modul_id == 17 || this.currentUser.role_id == 1) {
        const result = await this.showConfirmSwal();
        if (result.value) {
          if (this.users.length == 0) {
            this.showToast(
              "danger",
              "top-right",
              "Oops",
              "XIcon",
              "There's no users to select!"
            );
          } else {
            this.addPreloader();
            if (this.currentUser.role_id == 1) {
              this.module_send = this.module_id;
            } else {
              this.module_send = this.module_id;
            }
            const params = {
              id_module: this.module_send,
              start_date: moment(this.initDateSelect)
                .startOf("week")
                .add(-1, "week")
                .add(1, "day")
                .format("YYYY-MM-DD"),
              final_date: moment(this.hearChange)
                .startOf("week")
                .add(-1, "week")
                .add(7, "day")
                .format("YYYY-MM-DD"),
            };
            const res = await schedulesService.getUsersModule(params);
            let array = [];
            array = res.data;
            let f = 0;
            for (let i = 0; i < array.length; i++) {
              if (array[i].json_data != null && array[i].json_data.length > 1) {
                for (let y = 0; y < array[i].json_data.length; y++) {
                  for (let u = 0; u < this.users.length; u++) {
                    if (this.users[u] == array[i].id) {
                      this.schedules_users.push({
                        user: array[i].id,
                        id_super: this.currentUser.user_id,
                        date: moment(array[i].json_data[y].date)
                          .add(7, "day")
                          .format("YYYY-MM-DD"),
                        checking: array[i].json_data[y].checking,
                        checkout: array[i].json_data[y].checkout,
                        finish_break: array[i].json_data[y].finish_break,
                        begining_break: array[i].json_data[y].begining_break,
                        count_break: array[i].json_data[y].count_break,
                      });
                    }
                  }
                }
              }
            }
            const params2 = {
              schedules: this.schedules_users,
              id_module: this.module_id,
            };
            const response = await schedulesService.insertSchedulesBucle(
              params2
            );
            this.getModuleUsers();
            if (response.data.text == "0") {
              this.showToast(
                "warning",
                "top-right",
                "Warning",
                "AlertTriangleIcon",
                "Busy schedule detected!"
              );
            }
            this.users = [];
          }
        }
      } else {
        this.showErrorSwal("You are not a supervisor of this module!");
      }
    },
    openModalCopySchedules(item, sch) {
      if (sch.checking != null && sch.checkout != null) {
        this.addPreloader();
        this.modalCopy = true;
        this.dateCopied = sch.date;
        this.hoursCopy.checking = sch.checking;
        this.hoursCopy.beginin = sch.begining_break;
        this.hoursCopy.finish = sch.finish_break;
        this.hoursCopy.checkout = sch.checkout;
        this.hoursCopy.count_break = sch.count_break;
        this.hoursCopy.user = item.id;
        this.removePreloader();
      }
    },
    pushDates() {
      if (this.users.length == 0) {
        this.datesAdd.push({
          date: moment(this.dateCopied).format("YYYY-MM-DD"),
          checking: this.hoursCopy.checking,
          begining_break: this.hoursCopy.beginin,
          finish_break: this.hoursCopy.finish,
          checkout: this.hoursCopy.checkout,
          user: this.hoursCopy.user,
          id_super: this.currentUser.user_id,
          count_break: this.hoursCopy.count_break,
        });
      } else {
        this.users.push(this.hoursCopy.user);
        this.users.map((id) => {
          this.datesAdd.push({
            date: moment(this.dateCopied).format("YYYY-MM-DD"),
            checking: this.hoursCopy.checking,
            begining_break: this.hoursCopy.beginin,
            finish_break: this.hoursCopy.finish,
            checkout: this.hoursCopy.checkout,
            user: id,
            id_super: this.currentUser.user_id,
            count_break: this.hoursCopy.count_break,
          });
        });
      }
    },
    async loadModules() {
      const { data } = await schedulesService.listScheduleModules();
      this.modules = data;
    },
    formatNumber(number, width) {
      width -= number.toString().length;
      if (width > 0) {
        return (
          new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number
        );
      }
      return number + ""; // siempre devuelve tipo cadena
    },
    addHours() {
      this.hours;
      for (let i = 0; i <= 24; i++) {
        if (i < 24) {
          for (let y = 0; y <= 2; y++) {
            if (y == 0) {
              this.hours.push({
                hour: this.formatNumber(i, 2) + ":00",
                value: this.formatNumber(i, 2) + ":00:00.000000",
              });
            } else if (y == 1) {
              this.hours.push({
                hour: this.formatNumber(i, 2) + ":30",
                value: this.formatNumber(i, 2) + ":30:00.000000",
              });
            }
          }
        } else {
          this.hours.push({
            hour: i + ":00",
            value: i + ":00:00.000000",
          });
        }
      }
    },
    pusherUsers() {
      if (this.checkAll == true) {
        this.users = [];
        this.show.map((i) => {
          this.users.push(i.id);
        });
      } else {
        this.users = [];
      }
    },
  },
  watch: {
    initDateSelect(newVal, oldVal) {
      this.initDate = moment(newVal)
        .startOf("week")
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.endDate = moment(newVal)
        .startOf("week")
        .add(7, "days")
        .format("YYYY-MM-DD");
      var boton = document.getElementById("app");
      boton.classList.add("preloader");
      this.days.monday = moment(newVal)
        .startOf("week")
        .add(1, "days")
        .format("MM-DD");
      this.days.tuesday = moment(newVal)
        .startOf("week")
        .add(2, "days")
        .format("MM-DD");
      this.days.wednesday = moment(newVal)
        .startOf("week")
        .add(3, "days")
        .format("MM-DD");
      this.days.thursday = moment(newVal)
        .startOf("week")
        .add(4, "days")
        .format("MM-DD");
      this.days.friday = moment(newVal)
        .startOf("week")
        .add(5, "days")
        .format("MM-DD");
      this.days.saturday = moment(newVal)
        .startOf("week")
        .add(6, "days")
        .format("MM-DD");
      this.days.sunday = moment(newVal)
        .startOf("week")
        .add(7, "days")
        .format("MM-DD");
      var a = moment(newVal).startOf("week").add(1, "days");
      var b = moment().startOf("week").add(1, "days");
      this.week = a.diff(b, "weeks");
      this.getModuleUsers();
    },
    module_id(newVal, oldVal) {
      this.users = [];
      this.getModuleUsers();
    },
  },
  computed: {
    isAdvisor() {
      return this.currentUser.role_id == 3;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      currentBreakPoint: "app/currentBreakPoint",
    }),
    modulesReal() {
      if ([1, 2].includes(this.currentUser.role_id)) {
        return this.modules;
      }
      return this.modules.filter((modul) => modul.id != 17);
    },
  },
};
</script>


<template>
  <b-modal
    ref="modal-date-copy"
    :title="'DATE COPY'"
    @hidden="$emit('hidden')"
    ok-title="Copy"
    @ok.prevent="copyAll"
    scrollable
  >
    <b-row>
      <b-col cols="12" md="6">
        <kendo-datepicker
          :min="minDate"
          :max="maxDate"
          v-model="selectedDate"
          :format="'MM/dd/yyyy'"
          name="created_date"
          id="created_date"
          class="w-100"
        ></kendo-datepicker>
      </b-col>
      <b-col cols="12" md="6">
        <b-button @click="addDates()" class="w-100">ADD DATE</b-button>
      </b-col>
    </b-row>
    <b-list-group class="mt-1">
      <b-list-group-item v-for="(item, index) in isNoRepeat" :key="index" class="cursor-pointer">{{item.date | myGlobal}}</b-list-group-item>
    </b-list-group>
    <!-- <div>
      <div class="modal-body modal-scroll" id="modalBody">
        <div class="container">
          <div class="row centrar">
            <ul class="list-group" v-if="datesAdd.length">
              <li
                class="list-group-item my-2"
                v-for="(item, index) in isNoRepeat"
                :key="index"
              >
                * {{ item.date }}
                <i
                  class="fas fa-trash-alt"
                  style="cursor: pointer"
                  @click="deleteAdd(item)"
                ></i>
              </li>
            </ul>
            <p class="my-2" v-else>
              <b>no data found</b>
            </p>
          </div>
        </div>
      </div>
    </div> -->
  </b-modal>
</template>

<script>
import schedulesService from "../services/schedules.service";
import modalMixin from "@/mixins/modal";
import { mapGetters } from "vuex";
export default {
  mixins: [modalMixin],
  props: {
    dateCopied: {
      type: String,
      default: "",
    },
    datesAdd: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.toggleModal("modal-date-copy");
    this.selectedDate = this.dateCopied;
  },
  data() {
    return {
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(2050, 9, 1),
      selectedDate: null,
    };
  },
  computed: {
    isNoRepeat() {
      let arrayRepeat = this.datesAdd;
      return arrayRepeat.reduce((unique, o) => {
        if (!unique.some((obj) => obj.date === o.date)) {
          unique.push(o);
        }
        return unique;
      }, []);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    deleteAdd(item) {
      for (let i = this.datesAdd.length - 1; i >= 0; i--) {
        if (this.datesAdd[i].date == item.date) {
          this.datesAdd.splice(i, 1);
        }
      }
    },
    addDates() {
      this.$emit("push-dates");
    },
    onChangeDate(date) {
      this.$emit("change-date", date);
    },
    async copyAll() {
      if (this.currentUser.modul_id == 17 || this.currentUser.role_id == 1) {
        const result = await this.showConfirmSwal();
        if (result.value) {
          this.sendCopyAll();
        }
      } else {
        this.showErrorSwal("You are not a supervisor of this module!");
      }
    },
    async sendCopyAll() {
      if (this.datesAdd.length > 0) {
        this.addPreloader();
        const params = {
          schedules: this.datesAdd,
          id_module: this.currentUser.modul_id,
        };
        const res = await schedulesService.insertSchedulesBucle(params);
        if (res.data.text == "0") {
          this.showToast(
            "warning",
            "top-right",
            "Warning",
            "AlertTriangleIcon",
            "Busy schedule detected!"
          );
        } else if (res.data.text == 3) {
          this.showToast(
            "error",
            "top-right",
            "Oops",
            "XIcon",
            "Attendance has already been marked!"
          );
        }
        this.$emit("set-dates-add-empty");
        this.$emit("set-users-empty");
        this.$emit("close-modal-copy");
      } else {
        this.showErrorSwal("No days added!");
      }
    },
  },
  watch: {
    selectedDate(newVal) {
      this.onChangeDate(newVal);
    },
  },
};
</script>

<style>
</style>